import React from 'react';
import AssistantIcon from '@material-ui/icons/Assistant';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import constants from 'candy-commons/constants';
import {
    ArrayInput,
    Create,
    FormDataConsumer,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useTranslate,
} from 'react-admin';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import series from '../../data/series.json';
import { useStyles } from './styles';
const EcoEvents = constants.EcoEvents;
const EligibleAppliances = (props) => {
    const translation = useTranslate();
    const styles = useStyles();
    const breadcrumbsData = [
        {
            text: translation('resources.eligible-appliances.name'),
            url: 'eligible-appliances',
            icon: <AssistantIcon />,
        },
        {
            text: translation('resources.eligible-appliances.create.name'),
            url: '',
        },
    ];
    const getFilteredEvents = (applianceType) => {
        if (applianceType) {
            applianceType = applianceType === 'WM-WD' ? 'WM' : applianceType;
            return EcoEvents.filter((evt) =>
                evt?.applianceTypes.includes(applianceType)
            );
        }
        return [];
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list">
                    <ContainerFieldsList>
                        <ContainerFieldsList>
                            <TextInput
                                source="energyProviderKey"
                                label="Energy Provider"
                                required
                            />
                            <CustomSelectArrayInput
                                required
                                label="Countries"
                                source="countries"
                                choices={COUNTRIES}
                            />
                        </ContainerFieldsList>
                        <ArrayInput
                            className={styles.arrayInput}
                            source="applianceEligibility"
                            label="Appliance Eligibility"
                        >
                            <SimpleFormIterator
                                className={{ form: styles.form }}
                                getItemLabel={(index) => `${index + 1}-key`}
                            >
                                <CustomSelectInput
                                    source="applianceType"
                                    choices={applianceType}
                                    required
                                />
                                <TextInput source="country" required />
                                <CustomSelectInput
                                    source="brand"
                                    allowEmpty
                                    choices={brand}
                                />
                                <CustomSelectInput
                                    source="series"
                                    allowEmpty
                                    choices={series}
                                />
                                <TextInput source="seriesVersion" required />
                                <FormDataConsumer>
                                    {({
                                        formData,
                                        scopedFormData,
                                        getSource,
                                        ...rest
                                    }) => (
                                        <CustomSelectArrayInput
                                            choices={getFilteredEvents(
                                                scopedFormData?.applianceType
                                            )}
                                            translateChoice={false}
                                            label="Events Subscription"
                                            source={getSource(
                                                'eventSubscription'
                                            )}
                                            {...rest}
                                        />
                                    )}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default EligibleAppliances;
