import WorkIcon from '@material-ui/icons/Work';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
} from 'candy-commons-components';
import React from 'react';
import { BooleanField, TextField, TextInput, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const MaintenanceFilter = (props) => (
    <CustomFilter {...props}>
        <TextInput
            label="resources.maintenance.fields.search"
            source="q"
            alwaysOn
        />
    </CustomFilter>
);
const MaintenanceList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.maintenance.name'),
            url: '',
            icon: <WorkIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                exporter={false}
                perPage={25}
                {...props}
                filters={<MaintenanceFilter />}
                pagination={<DynamoPagination resource="maintenance" />}
                bulkActionButtons={
                    permissions &&
                    permissions.maintenance &&
                    permissions.maintenance.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="productLine" />
                    <TextField source="country" />
                    <TextField source="brand" />
                    <BooleanField source="generic" />
                    <TextField source="interfaceType" />
                    <TextField source="link" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default MaintenanceList;
