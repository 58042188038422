import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import {
    BooleanField,
    CreateButton,
    TextField,
    useTranslate,
    TextInput,
} from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import config from '../../config';
import applianceType from '../../data/applianceType.json';
import series from '../../data/series.json';
import seriesVersion from '../../data/seriesVersion.json';
import brands from '../../data/brand.json';
const OnboardingFilter = (props) => {
    return (
        <CustomFilter {...props}>
            <CustomSelectInput
                source="applianceType"
                choices={applianceType}
                alwaysOn
                initialValue=""
                allowEmpty={true}
            />
            <CustomSelectInput
                source="brand"
                allowEmpty
                alwaysOn
                choices={brands}
            />
            <CustomSelectInput source="series" choices={series} alwaysOn />
            <CustomSelectInput
                source="series"
                choices={seriesVersion}
                alwaysOn
            />
            <TextInput label="Event Code" source="q" alwaysOn />
        </CustomFilter>
    );
};
const OnboardingList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.onboarding.name'),
            url: '',
            icon: <SettingsIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                pagination={<DynamoPagination resource="onboarding" />}
                exporter={false}
                filters={<OnboardingFilter />}
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField
                        source="applianceType"
                        label={'Appliance Type'}
                    />
                    <TextField source="eventCode" label={'Event Code'} />
                    <TextField source="brand" label={'Brand'} />
                    <TextField source="series" label={'Series'} />
                    <TextField
                        source="seriesVersion"
                        label={'Series Version'}
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default OnboardingList;
