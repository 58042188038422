import generalReducer from './general.reducers';
import paginationReducer from './pagination.reducers';
const initialState = {};
const rootReducer = (state = initialState, action) => {
    let newState = {
        ...state,
    };
    newState = generalReducer(newState, action);
    newState = paginationReducer(newState, action);
    return newState;
};
export default rootReducer;
