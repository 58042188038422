import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import {
    BooleanField,
    CreateButton,
    TextField,
    useTranslate,
} from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import config from '../../config';
import applianceType from '../../data/applianceType.json';
import series from '../../data/series.json';
import { brands } from '../maintenanceInstructions/data';
import options from './applianceWakeUp.mock';
const { basePath: apiPath } = config;
const ApplianceWakeUpFilter = (props) => {
    return (
        <CustomFilter {...props}>
            <CustomSelectInput
                source="applianceType"
                choices={applianceType}
                alwaysOn
                initialValue=""
                allowEmpty={false}
            />
            <CustomSelectInput
                source="brand"
                allowEmpty
                alwaysOn
                choices={brands}
            />
            <CustomSelectInput source="series" choices={series} alwaysOn />
            <CustomSelectInput
                allowEmpty
                source="options"
                label="enrollment Type"
                choices={options}
            />
        </CustomFilter>
    );
};
const AssetListAction = ({
    basePath,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    permissions,
}) => {
    const username = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
    );
    const token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`
    );
    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {permissions && permissions.asset && permissions.asset.create && (
                <CreateButton basePath={basePath} />
            )}
        </Toolbar>
    );
};
const ApplianceWakeUpList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.appliance-wake-up.name'),
            url: '',
            icon: <SettingsIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                exporter={false}
                filters={<ApplianceWakeUpFilter />}
                actions={<AssetListAction permissions={permissions} />}
                bulkActionButtons={
                    permissions && permissions.asset && permissions.asset.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="applianceType" />
                    <TextField source="brand" />
                    <TextField source="series" />
                    <TextField source="seriesVersion" />
                    <TextField source="loadingType" label="Loading Type" />
                    <TextField
                        source="installationType"
                        label="Installation Type"
                    />
                    <TextField source="display" label="Display" />
                    <BooleanField source="resetGesture" />
                    <BooleanField source="easyEnrollment" />
                    <BooleanField source="standardEnrollment" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default ApplianceWakeUpList;
