import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WorkIcon from '@material-ui/icons/Work';
import { Storage } from 'aws-amplify';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    ArrayInput,
    DeleteButton,
    Edit,
    ImageField,
    SaveButton,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Toolbar,
    required,
    useTranslate,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import ImageInput from '../../components/ImageInput';
import APPLIANCE_TYPE from '../../data/applianceType.json';
import DRAWER_TYPE from '../../data/drawerType.json';
import CATEGORIES from '../../data/maintenanceInstructionsCategories.json';
import SERIES from '../../data/series.json';
import SERIES_VERSION from '../../data/seriesVersion.json';
import LOADING_TYPE from '../../data/loadingType.json';
import INSTALLATION_TYPE from '../../data/installationType.json';
import DRYING_TECHNOLOGY from '../../data/dryingTechnology.json';
import TANK_POSITION from '../../data/tankPosition.json';
import { brands as BRANDS } from './data';
const { v4: uuid } = require('uuid');
const filteredSeries = SERIES.filter((x) => Boolean(x.id));
const MaintenanceEditSaveButton = ({
    handleSubmitWithRedirect,
    isEditDisabled,
    ...props
}) => {
    const form = useForm();
    const formState = useFormState();
    const handleClick = () => {
        if (formState.values.generic) form.change('link', null);
        handleSubmitWithRedirect('list');
    };
    return (
        <SaveButton
            {...props}
            handleSubmitWithRedirect={handleClick}
            disabled={isEditDisabled}
        />
    );
};
const MaintenanceEditToolbar = ({
    permissions = {},
    resource,
    isEditDisabled,
    ...props
}) => (
    <Toolbar {...props}>
        <MaintenanceEditSaveButton isEditDisabled={isEditDisabled} />
        <span style={{ flex: '1' }} />
        {permissions[resource] &&
            permissions[resource].delete &&
            !isEditDisabled && <DeleteButton undoable />}
    </Toolbar>
);
const handleClick = async (event) => {
    const file = event;
    if (!file) {
        return;
    }
    const key = `maintenance/${file.name}`;
    const response = await Storage.put(key, file, {
        contentType: file.type,
    });
};
const MaintenanceEdit = ({ permissions, ...props }) => {
    const classes = useStyles();
    const { id } = props;
    const translation = useTranslate();
    const isEditDisabled = !permissions?.maintenance?.update;
    const pageTitle = `Id: ${id}`;
    const breadcrumbsData = [
        {
            text: translation('resources.maintenance-instructions.name'),
            url: 'maintenance-instructions',
            icon: <WorkIcon />,
        },
        {
            text: pageTitle,
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <MaintenanceEditToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <Grid container style={{ width: '100%', paddingLeft: 10 }}>
                        <Typography>Criteria</Typography>
                    </Grid>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            allowEmpty
                            source="applianceType"
                            validate={required()}
                            choices={APPLIANCE_TYPE}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="brand"
                            allowEmpty
                            choices={BRANDS}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="series"
                            allowEmpty
                            choices={filteredSeries}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="seriesVersion"
                            allowEmpty
                            choices={SERIES_VERSION}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            label="Loading Type"
                            source="loadingType"
                            allowEmpty
                            choices={LOADING_TYPE}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            label="Drawer Type"
                            source="drawerType"
                            allowEmpty
                            choices={DRAWER_TYPE}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            label="Installation Type"
                            source="installationType"
                            allowEmpty
                            choices={INSTALLATION_TYPE}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            label="Drying Technology"
                            source="dryingTechnology"
                            allowEmpty
                            choices={DRYING_TECHNOLOGY}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            label="Tank Position"
                            source="tankPosition"
                            allowEmpty
                            choices={TANK_POSITION}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <Divider style={{ width: '100%' }} />
                    <Grid container style={{ width: '100%', paddingLeft: 10 }}>
                        <Typography>Maintenance Categoy</Typography>
                    </Grid>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="category"
                            allowEmpty
                            validate={required()}
                            choices={CATEGORIES}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <Divider style={{ width: '100%' }} />
                    <ArrayInput source="steps" disabled={isEditDisabled}>
                        <SimpleFormIterator
                            getItemLabel={(index) => `${index + 1}-key`}
                        >
                            <TextInput
                                label="Title"
                                source="title"
                                validate={required()}
                            />
                            <TextInput
                                label="Description"
                                source="description"
                            />
                            <TextInput label="CTA Label" source="cta_label" />
                            {!isEditDisabled && (
                                <ImageInput
                                    source="imagePath"
                                    label="Image"
                                    accept="image/*"
                                    onChange={handleClick}
                                    placeholder={<p>Drop your file here</p>}
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            )}
                        </SimpleFormIterator>
                    </ArrayInput>
                    <Divider style={{ width: '100%', marginBottom: 20 }} />
                    <ContainerFieldsList>
                        <Grid
                            container
                            style={{ width: '100%', paddingLeft: 10 }}
                        >
                            <Typography>Confirmation</Typography>
                        </Grid>
                        <TextInput
                            source="confirmationTitle"
                            allowEmpty
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="confirmationDesc"
                            allowEmpty
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="confirmationLabel"
                            allowEmpty
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="cancelLabel"
                            allowEmpty
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    reverseButtonSave: {
        '& .MuiToolbar-root': {
            flexDirection: 'row-reverse',
        },
    },
});
export default MaintenanceEdit;
