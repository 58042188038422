import React from 'react';
import WorkIcon from '@material-ui/icons/Work';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    BooleanInput,
    Create,
    FormDataConsumer,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    useTranslate,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import applianceType from '../../data/applianceType.json';
import series from '../../data/series.json';
import { brands } from '../maintenanceInstructions/data';
import COUNTRIES from '../../data/countries.json';
const MaintenanceCreateSaveButton = ({
    handleSubmitWithRedirect,
    ...props
}) => {
    const form = useForm();
    const formState = useFormState();
    const handleClick = () => {
        if (formState.values.generic) form.change('link', null);
        handleSubmitWithRedirect('list');
    };
    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};
const MaintenanceCreateToolbar = (props) => (
    <Toolbar {...props}>
        <MaintenanceCreateSaveButton />
    </Toolbar>
);
const MaintenanceCreate = (props) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.maintenance.name'),
            url: 'maintenance',
            icon: <WorkIcon />,
        },
        {
            text: translation('resources.maintenance.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm toolbar={<MaintenanceCreateToolbar />}>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="country"
                            choices={COUNTRIES}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="productLine"
                            choices={applianceType}
                            validate={required()}
                        />
                        <CustomSelectInput
                            source="brand"
                            allowEmpty
                            choices={brands}
                            validate={required()}
                        />
                        <CustomSelectInput
                            source="interfaceType"
                            allowEmpty
                            choices={series}
                        />
                        <BooleanInput source="generic" />
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                !formData.generic && (
                                    <TextInput
                                        source="link"
                                        {...rest}
                                        validate={required()}
                                    />
                                )
                            }
                        </FormDataConsumer>
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default MaintenanceCreate;
