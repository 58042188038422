import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import applianceType from '../../data/applianceType.json';
const SmartCheckupEdit = ({ permissions = {}, ...props }) => {
    const { id } = props;
    const isEditDisabled = !permissions?.['smart-checkup']?.update;
    const breadcrumbsData = [
        {
            text: 'Smart Checkup',
            url: 'smart-checkup',
            icon: <ErrorIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    {/* <ShowList /> */}
                    <ContainerFieldsList>
                        <TextInput
                            source="errorCode"
                            validate={required()}
                            label="Technical Error Code"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceType}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput source="series" />
                        <TextInput source="title" />
                        <TextInput source="description" />
                        <TextInput source="cdmParameter" />
                        <TextInput source="seriesVersion" />
                        <TextInput source="category" />
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default SmartCheckupEdit;
