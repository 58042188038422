import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    CustomBreadcrumbs,
    CustomList,
    CustomFilter,
} from 'candy-commons-components';
import React from 'react';
import {
    TextField,
    useTranslate,
    TextInput,
    SelectInput,
    NumberField,
} from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import COUNTRIES from '../../data/countries.json';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const AppBundleUpdateFilter = (props) => {
    return (
        <CustomFilter {...props}>
            <TextInput label="Search version" source="q" alwaysOn />
        </CustomFilter>
    );
};
const AppBundleUpdateList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.app-bundle-update.name'),
            url: '',
            icon: <ErrorIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                pagination={<DynamoPagination resource="app-bundle-update" />}
                exporter={false}
                filters={<AppBundleUpdateFilter />}
                {...props}
                bulkActionButtons={
                    permissions &&
                    permissions['app-bundle-update'] &&
                    permissions['app-bundle-update'].delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="version" label={'Version'} />
                    <TextField source="country" label={'Country'} />
                    <NumberField
                        source="maxDownloads"
                        label={'Max Downloads'}
                    />
                    <NumberField
                        source="downloadsCounter"
                        label={'Downloads Counter'}
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default AppBundleUpdateList;
