import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { Storage } from 'aws-amplify';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    Create,
    ImageField,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import countries from '../../data/programListRulesCountries.json';
import ImageInput from '../../components/ImageInput';
import _brand from '../../data/brand.json';
import applianceType from '../../data/programListRulesApplianceType.json';
import series from '../../data/series.json';
const brand = _brand.filter((x) => x.id !== 'all');
const useStyles = makeStyles({
    divider: { borderTop: '1px solid #ddd', width: '100%' },
    wrapper: { width: '100%', padding: '0 10px' },
});
const ProgramRulesCreate = (props) => {
    const classes = useStyles();
    const _t = useTranslate();
    const breadcrumbsData = [
        {
            text: 'Program list rules',
            url: 'program-list-rules',
            icon: <SettingsIcon />,
        },
        {
            text: 'Create',
            url: '',
        },
    ];
    const handleClick = async (event) => {
        const file = event;
        if (!file) {
            return;
        }
        const key = `program-list-rules/${file.name}`;
        await Storage.put(key, file, {
            contentType: file.type,
        });
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceType}
                            validate={required()}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brand}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                        />
                        <TextInput source="seriesVersion" />
                        <TextInput multiline source="additionalModelParams" />
                        <CustomSelectArrayInput
                            label="Countries"
                            source="countries"
                            choices={countries}
                            parse={(x) => (x.includes('ALL') ? ['ALL'] : x)}
                        />
                        <Divider
                            style={{ width: '100%' }}
                            className={classes.divider}
                        />
                        <Grid
                            container
                            style={{ width: '100%', padding: '0 10px 10px' }}
                            direction="column"
                        >
                            <Typography style={{ paddingTop: 10 }}>
                                {_t('Association')}
                            </Typography>
                            <Grid container>
                                <Grid item xs={4} style={{ paddingRight: 15 }}>
                                    <CustomSelectInput
                                        style={{ width: '100%' }}
                                        label={_t(
                                            'resources.program-list-rules.fields.associationType'
                                        )}
                                        source="associationType"
                                        choices={[
                                            {
                                                id: 'programName',
                                                name: 'Program Name',
                                            },
                                            {
                                                id: 'cluster',
                                                name: 'Cluster',
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput
                                        style={{ width: '100%' }}
                                        label={_t(
                                            'resources.program-list-rules.fields.associationValue'
                                        )}
                                        source="associationValue"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider
                            style={{ width: '100%' }}
                            className={classes.divider}
                        />
                        <Grid
                            container
                            style={{ width: '100%', padding: '0 10px' }}
                        >
                            <Grid item xs={6} style={{ paddingRight: 3 }}>
                                <ImageInput
                                    source="iconPath"
                                    label="Icon"
                                    accept="image/*"
                                    onChange={handleClick}
                                    placeholder={
                                        <p>
                                            {_t(
                                                'resources.asset.fields.input_file'
                                            )}
                                        </p>
                                    }
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: 3 }}>
                                <ImageInput
                                    source="imagePath"
                                    label="Image"
                                    accept="image/*"
                                    onChange={handleClick}
                                    placeholder={
                                        <p>
                                            {_t(
                                                'resources.asset.fields.input_file'
                                            )}
                                        </p>
                                    }
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            </Grid>
                        </Grid>
                        {/*<CustomSelectInput allowEmpty source="options" choices={options} />*/}
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default ProgramRulesCreate;
