import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
    FieldsGenerator,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    ArrayInput,
    BooleanInput,
    Edit,
    ImageField,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextInput,
    required,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import connectivityOptions from '../../data/connectivityOptions.json';
import { ServiceAppSyncValues } from './commonComponents';
import LOADING_TYPE from '../../data/loadingType.json';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const TroubleshootingEdit = ({ permissions = {}, ...props }) => {
    const [tdChoices, setTdChoices] = useState(false);
    const isEditDisabled = !permissions?.troubleshooting?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: 'Troubleshooting',
            url: 'troubleshooting',
            icon: <ErrorIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator
            fields={[
                {
                    label: 'errorCode',
                    type: 'textField',
                    source: 'errorCode',
                },
                {
                    label: 'brand',
                    type: 'textField',
                    source: 'brand',
                },
                {
                    label: 'applianceType',
                    type: 'textField',
                    source: 'applianceType',
                },
                {
                    label: 'applianceModel',
                    type: 'textField',
                    source: 'applianceModel',
                },
                {
                    label: 'title',
                    type: 'textField',
                    source: 'title',
                },
                {
                    label: 'type',
                    type: 'textField',
                    source: 'type',
                },
                {
                    label: 'tankType',
                    type: 'textField',
                    source: 'tankType',
                },
                {
                    label: 'dryingTechnology',
                    type: 'textField',
                    source: 'dryingTechnology',
                },
                {
                    label: 'category',
                    type: 'CustomSelectInput',
                    source: 'category',
                },
            ]}
            record={record}
            {...props}
        />
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    {/* <ShowList /> */}
                    <ContainerFieldsList>
                        <TextInput
                            source="errorCode"
                            label="Technical Error Code"
                            disabled
                        />
                        <TextInput source="brand" disabled />
                        <TextInput source="applianceType" disabled />
                        <TextInput source="applianceModel" disabled />
                        <CustomSelectInput
                            allowEmpty
                            source="loadingType"
                            choices={LOADING_TYPE}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="title"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="type"
                            choices={[
                                { id: 'monoSolution', name: 'MONO-SOLUTION' },
                                { id: 'multiSolution', name: 'MULTI-SOLUTION' },
                            ]}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="category"
                            choices={[
                                { id: 'error', name: 'ERROR' },
                                { id: 'warning', name: 'WARNING' },
                                { id: 'none', name: 'NONE' },
                            ]}
                            disabled={isEditDisabled}
                        />
                        <TextInput source="platform" disabled />
                        <TextInput
                            source="userErrorCode"
                            label="User Error Code"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            style={{
                                opacity: tdChoices ? 1 : 0,
                                height: tdChoices ? undefined : 0,
                            }}
                            disabled={!tdChoices || isEditDisabled}
                            source="tankType"
                            allowEmpty
                            choices={[
                                { id: 'B', name: 'Bottom' },
                                { id: 'D', name: 'Door' },
                                { id: 'NA', name: 'Not available' },
                            ]}
                        />
                        <CustomSelectArrayInput
                            style={{
                                opacity: tdChoices ? 1 : 0,
                                height: tdChoices ? undefined : 0,
                            }}
                            disabled={!tdChoices || isEditDisabled}
                            source="dryingTechnology"
                            allowEmpty
                            choices={[
                                { id: 'C', name: 'Condenser' },
                                { id: 'HP', name: 'Heat pump' },
                                { id: 'HY', name: 'Hybrid' },
                                { id: 'V', name: 'Vented' },
                            ]}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="connectivity"
                            allowEmpty
                            choices={connectivityOptions}
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            source="serviceSync"
                            label="Active Service Sync"
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            source="serviceAppSync"
                            label="Service App Sync"
                            disabled={isEditDisabled}
                        />
                        <ServiceAppSyncValues />
                    </ContainerFieldsList>
                    <ArrayInput source="steps" disabled={isEditDisabled}>
                        <SimpleFormIterator>
                            <SimpleShowLayout>
                                <ImageField source="imagePath" label="" />
                            </SimpleShowLayout>
                            <TextInput
                                label="title"
                                source="title"
                                validate={required()}
                            />
                            <TextInput
                                label="description"
                                source="description"
                            />
                            <CustomSelectInput
                                source="category"
                                choices={[
                                    { id: 'error', name: 'error' },
                                    { id: 'warning', name: 'warning' },
                                    { id: 'none', name: 'none' },
                                ]}
                            />
                            {!isEditDisabled && (
                                <ImageInput
                                    source="imagePath"
                                    label="Image"
                                    accept="image/*"
                                    placeholder={<p>Drop your file here</p>}
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            )}
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default TroubleshootingEdit;
