import React from 'react';
import RowingIcon from '@material-ui/icons/Rowing';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import { FunctionField, TextField, TextInput, useTranslate } from 'react-admin';
import applianceType from '../../data/applianceType.json';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const HintsCategoryFilter = (props) => (
    <CustomFilter {...props}>
        <TextInput
            label="resources.hints-category.fields.search"
            source="q"
            alwaysOn
        />
        <CustomSelectInput
            source="applianceType"
            choices={applianceType}
            alwaysOn
            initialValue=""
            allowEmpty={false}
        />
    </CustomFilter>
);
const HintCategoryList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.hints-category.name'),
            url: '',
            icon: <RowingIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                exporter={false}
                perPage={25}
                {...props}
                pagination={<DynamoPagination resource="hints" />}
                filters={<HintsCategoryFilter />}
                bulkActionButtons={
                    permissions && permissions.hints && permissions.hints.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="applianceType" />
                    <TextField source="brand" />
                    <FunctionField
                        label="Series"
                        render={(item) => {
                            const { series } = item;
                            if (series && Array.isArray(series)) {
                                return series.join(',');
                            }
                            return series;
                        }}
                        source="series"
                    />
                    <FunctionField
                        label="Series Version"
                        render={(item) => {
                            const { seriesVersion } = item;
                            if (seriesVersion && Array.isArray(seriesVersion)) {
                                return seriesVersion.join(',');
                            }
                            return seriesVersion;
                        }}
                        source="seriesVersion"
                    />
                    <TextField source="code" />
                    <TextField source="title" />
                    <FunctionField
                        label="Countries"
                        render={(item) => (item.countries || []).join(',')}
                        source="countries"
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default HintCategoryList;
