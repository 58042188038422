import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
} from 'candy-commons-components';
import moment from 'moment';
import React, { useState } from 'react';
import {
    BooleanInput,
    DateInput,
    Edit,
    FormDataConsumer,
    ImageField,
    NumberInput,
    SimpleForm,
    SimpleShowLayout,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import APPLIANCE_TYPES from '../../data/applianceType.json';
import BRANDS from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import discoverOrderValidator from '../../validators/discoverOrderValidator';
const GetFeedBackTargetEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const [allApplianceFlag, setAllApplianceFlag] = useState(false);
    const [noApplianceFlag, setNoApplianceFlag] = useState(false);
    const { id } = props;
    const translation = useTranslate();
    const isEditDisabled = !permissions?.['getfeedback-target']?.update;
    const breadcrumbsData = [
        {
            text: translation('resources.getfeedback-target.name'),
            url: 'getfeedback-target',
            icon: <PageviewIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const handleAllAppliance = (e) => setAllApplianceFlag(e.target.checked);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <TextInput
                            label="Event name"
                            source="eventName"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            initialValue={false}
                            source="isActive"
                            label="Active"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <>
                                    {!formData.AllAppliance &&
                                        !allApplianceFlag && (
                                            <CustomSelectArrayInput
                                                label="Appliance types"
                                                source="applianceType"
                                                choices={APPLIANCE_TYPES}
                                                disabled={
                                                    allApplianceFlag ||
                                                    isEditDisabled
                                                }
                                            />
                                        )}
                                    <BooleanInput
                                        label="All appliance"
                                        source="isAllAppliance"
                                        disabled={
                                            formData.NoAppliance ||
                                            isEditDisabled
                                        }
                                        onClick={handleAllAppliance}
                                    />
                                </>
                            )}
                        </FormDataConsumer>
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <BooleanInput
                            label="No enrolled appliance"
                            source="isNoAppliance"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <CustomSelectArrayInput
                            label="Countries"
                            source="countries"
                            choices={COUNTRIES}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        {/* <CustomSelectArrayInput
              label="Brands"
              source="brand"
              choices={BRANDS}
            /> */}
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default GetFeedBackTargetEdit;
