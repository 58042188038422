import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import { FunctionField, TextField, TextInput } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import _brand from '../../data/brand.json';
import applianceType from '../../data/programListRulesApplianceType.json';
import series from '../../data/series.json';
const brand = _brand.filter((x) => x.id !== 'all');
const breadcrumbsData = [
    {
        text: 'Program List Rules',
        url: '',
        icon: <SettingsIcon />,
    },
];
const Filters = (props) => (
    <CustomFilter {...props}>
        <CustomSelectInput
            source="applianceType"
            choices={applianceType}
            alwaysOn
            initialValue=""
            allowEmpty
        />
        <CustomSelectInput source="brand" allowEmpty alwaysOn choices={brand} />
        <CustomSelectInput source="series" choices={series} alwaysOn />
        <TextInput source="seriesVersion" alwaysOn />
    </CustomFilter>
);
const ProgramListRulesList = ({ permissions, ...props }) => (
    <>
        <CustomBreadcrumbs data={breadcrumbsData} />
        <CustomList
            filters={<Filters />}
            perPage={25}
            exporter={false}
            pagination={<DynamoPagination resource="asset" />}
            {...props}
            bulkActionButtons={
                permissions &&
                permissions['program-list-rules'] &&
                permissions['program-list-rules'].delete
            }
        >
            <CustomDatagrid selectable rowClick="edit">
                <TextField source="applianceType" />
                <TextField source="brand" />
                <TextField source="series" />
                <TextField source="seriesVersion" />
                <FunctionField
                    label="Countries"
                    render={(item) => (item.countries || []).join(',')}
                    source="countries"
                />
            </CustomDatagrid>
        </CustomList>
    </>
);
export default ProgramListRulesList;
