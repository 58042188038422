import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    CustomBreadcrumbs,
    CustomList,
    CustomFilter,
} from 'candy-commons-components';
import React from 'react';
import { TextField, useTranslate, TextInput, BooleanField } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const AppUserInfoFilter = (props) => (
    <CustomFilter {...props}>
        <TextInput
            label="resources.app-user-info.fields.search"
            source="q"
            alwaysOn
        />
    </CustomFilter>
);
const AppUserInfoList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.app-user-info.name'),
            url: '',
            icon: <ErrorIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                pagination={<DynamoPagination resource="app-user-info" />}
                exporter={false}
                {...props}
                filters={<AppUserInfoFilter />}
                bulkActionButtons={
                    permissions &&
                    permissions['app-user-info'] &&
                    permissions['app-user-info'].delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="PK" />
                    <TextField source="SK" />
                    <TextField source="email" />
                    <BooleanField source="canSubmitFeedback" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default AppUserInfoList;
