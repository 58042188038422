import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { Storage } from 'aws-amplify';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    ArrayInput,
    Create,
    FileField,
    FileInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    required,
} from 'react-admin';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import languages from '../../data/languages.json';
import series from '../../data/series.json';
const breadcrumbsData = [
    {
        text: 'Video Tutorials',
        url: '',
        icon: <SettingsIcon />,
    },
    {
        text: 'Create',
        url: '',
    },
];
let photoUrl;
// const [isLoading, setLoading] = useState(false);
// const notify = useNotify();
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const VideoTutorialCreate = (props) => {
    const classes = useStyles();
    // const {
    //   bucketBea
    // } = config;
    const handleClick = async (event) => {
        // setLoading(true);
        const file = event;
        if (!file) {
            // notify('no file');
            return;
        }
        const key = `videoTutorial/${file.name}`;
        const upload = await Storage.put(key, file, {
            contentType: file.type,
            // bucket: bucketBea
        });
        // setLoading(false);
        // notify('resources.language.notification.import-all-completed');
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        {/*Appliance type*/}
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceType}
                            validate={[required()]}
                        />
                        {/*Brand*/}
                        <CustomSelectInput source="brand" choices={brand} />
                        <CustomSelectInput source="series" choices={series} />
                        <TextInput
                            source="link"
                            label="Link"
                            validate={[required()]}
                        />
                        <TextInput
                            source="title"
                            label="Title"
                            validate={[required()]}
                        />
                        <FileInput
                            source="photo"
                            label="photo(jpeg, 152x107)"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={handleClick}
                            validate={[required()]}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                        {/*Languages*/}
                        <ArrayInput source="language" validate={[required()]}>
                            <SimpleFormIterator disableRemove>
                                <CustomSelectInput
                                    choices={languages}
                                    validate={[required()]}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default VideoTutorialCreate;
