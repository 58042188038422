import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import applianceType from '../../data/applianceType.json';
const SmartCheckupCreate = (props) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.smart-checkup.name'),
            url: 'smart-checkup',
            icon: <ErrorIcon />,
        },
        {
            text: transaltion('resources.smart-checkup.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <TextInput
                            source="errorCode"
                            validate={required()}
                            label="Technical Error Code"
                        />
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceType}
                            validate={required()}
                        />
                        <TextInput source="series" />
                        <TextInput source="seriesVersion" />
                        <TextInput source="title" />
                        <TextInput source="description" />
                        <TextInput source="cdmParameter" />
                        <TextInput source="category" />
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default SmartCheckupCreate;
