import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    ArrayInput,
    Create,
    ImageField,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useTranslate,
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import serviceability from '../../data/serviceability.json';
import series from '../../data/series.json';
import LOADING_TYPE from '../../data/loadingType.json';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const ServiceModeCreate = (props) => {
    const [tdChoices, setTdChoices] = useState(false);
    const classes = useStyles();
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.service-mode.name'),
            url: 'service-mode',
            icon: <ErrorIcon />,
        },
        {
            text: transaltion('resources.service-mode.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceType}
                            validate={required()}
                            onChange={(event) => {
                                try {
                                    if (event.target.value === 'TD') {
                                        setTdChoices(true);
                                    } else {
                                        setTdChoices(false);
                                    }
                                } catch (_error) {
                                    // ignore
                                }
                            }}
                        />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="brand"
                            choices={[
                                ...brand,
                                {
                                    id: 'TERZISMO',
                                    name: 'TERZISMO',
                                },
                            ]}
                        />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="series"
                            choices={series.sort((a, b) =>
                                a.name.localeCompare(b.name)
                            )}
                        />
                        <TextInput source="seriesVersion" />
                        <CustomSelectInput
                            source="loadingType"
                            allowEmpty
                            choices={LOADING_TYPE}
                        />
                        <TextInput source="installationType" />
                        <TextInput source="display" />
                        <TextInput source="title" />
                        <TextInput source="description" />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="serviceability"
                            choices={serviceability}
                        />
                    </ContainerFieldsList>
                    <hr className={classes.width} />
                    <ArrayInput source="steps">
                        <SimpleFormIterator>
                            {/* <TextInput source="title" /> */}
                            <TextInput source="description" />
                            <ImageInput
                                source="imagePath"
                                label="Image"
                                accept="image/*"
                                placeholder={
                                    <p>
                                        {transaltion(
                                            'resources.ServiceMode.fields.input_file'
                                        )}
                                    </p>
                                }
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default ServiceModeCreate;
