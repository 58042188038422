import { makeStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import { TextField, useTranslate } from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import DynamoPagination from '../../components/DynamoPagination';
const SupportList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.support.name'),
            url: '',
            icon: <ContactSupportIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                pagination={<DynamoPagination resource="support" />}
                exporter={false}
                bulkActionButtons={
                    permissions &&
                    permissions.support &&
                    permissions.support.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="applianceType" />
                    <TextField source="brand" />
                    <TextField source="series" />
                    <TextField source="loadingType" />
                    <TextField source="title" />
                    <TextField source="type" />
                    <TextField source="screen" />
                    <TextField source="iconPath" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
const useStyles = makeStyles({});
export default SupportList;
