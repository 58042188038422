import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    ArrayInput,
    BooleanInput,
    Create,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import APPLIANCE_TYPES from '../../data/applianceType.json';
import BRANDS from '../../data/brand.json';
import COUNTRIES from '../../data/countries.json';
import SERIES from '../../data/series.json';
import LOADING_TYPE from '../../data/loadingType.json';
import { CONNECTIVITY } from './applianceConnectivityChoices';
import { sectionChoices } from './sectionChoices';
const breadcrumbsData = [
    {
        text: 'Appliance Configuration',
        url: 'appliance-configuration',
        icon: <ViewComfyIcon />,
    },
    {
        text: 'Create',
        url: '',
    },
];
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const ApplianceConfigurationCreate = (props) => {
    const classes = useStyles();
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={APPLIANCE_TYPES}
                        />
                        <TextInput source="applianceModel" />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={SERIES}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={BRANDS}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="country"
                            choices={COUNTRIES}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="connectivity"
                            choices={CONNECTIVITY}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="loadingType"
                            choices={LOADING_TYPE}
                        />
                    </ContainerFieldsList>
                    <hr className={classes.width} />
                    <ArrayInput source="sections">
                        <SimpleFormIterator>
                            <CustomSelectInput
                                source="title"
                                choices={sectionChoices.sort((a, b) =>
                                    a.id.localeCompare(b.id)
                                )}
                            />
                            <BooleanInput label="" source="visibility" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="topicsSubscribe">
                        <SimpleFormIterator>
                            <TextInput source="topic" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="topicsPublish">
                        <SimpleFormIterator>
                            <TextInput source="topic" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default ApplianceConfigurationCreate;
