import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    FieldsGenerator,
} from 'candy-commons-components';
import React from 'react';
import {
    ArrayInput,
    Edit,
    ImageField,
    required,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextInput,
    useTranslate,
} from 'react-admin';
import { showListData } from './applianceInfoStaticData';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
const ApplianceInfoEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const { id } = props;
    const translation = useTranslate();
    const isEditDisabled = !permissions?.['appliance-info']?.update;
    const breadcrumbsData = [
        {
            text: translation('resources.appliance-info.name'),
            url: 'appliance-info',
            icon: <InfoIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator fields={showListData} record={record} {...props} />
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <ContainerFieldsList>
                        <TextInput
                            source="description"
                            disabled={isEditDisabled}
                        />
                        <TextInput source="title" disabled={isEditDisabled} />
                    </ContainerFieldsList>
                    <ArrayInput source="images" disabled={isEditDisabled}>
                        <SimpleFormIterator>
                            <SimpleShowLayout>
                                <ImageField source="path" addLabel={false} />
                            </SimpleShowLayout>
                            <TextInput
                                source="description"
                                validate={required()}
                            />
                            {!isEditDisabled && (
                                <ImageInput
                                    source="path"
                                    accept="image/*"
                                    placeholder={
                                        <p>
                                            {translation(
                                                'resources.appliance-info.fields.input_file'
                                            )}
                                        </p>
                                    }
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            )}
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
export default ApplianceInfoEdit;
