import React from 'react';
import { Create, SimpleForm, useTranslate, BooleanInput } from 'react-admin';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
} from 'candy-commons-components';
const AppUserInfoCreate = (props) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.app-user-info.name'),
            url: 'app-user-info',
        },
        {
            text: transaltion('resources.app-user-info.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <BooleanInput source="canSubmitFeedback" />
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default AppUserInfoCreate;
