import React, { useMemo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import {
    ContainerFieldsList,
    FieldsGenerator,
    CustomBreadcrumbs,
} from 'candy-commons-components';
import {
    Edit,
    FileField,
    FileInput,
    SimpleForm,
    TextInput,
    TextField,
    required,
    useTranslate,
    FunctionField,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import applianceType from '../../data/applianceType.json';
const showListData = [
    {
        label: 'resources.mocks.fields.code',
        type: 'textField',
        source: 'code',
    },
];
const ASSET_FOLDER = 'public/asset';
const AwsGetField = (props) => {
    const [mock, setMock] = useState('');
    const record = props?.record;
    const fetchMock = async () => {
        const response = await fetch(record?.mockPath);
        const jsonObject = await response.json();
        return jsonObject;
    };
    useEffect(() => {
        if (!record) return;
        const fetchData = async () => {
            let tempMock = await fetchMock();
            tempMock = JSON.stringify(tempMock, null, 2);
            setMock(tempMock);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);
    if (!record) return null;
    return <pre>{mock}</pre>;
};
const MockDemoEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const translation = useTranslate();
    const isEditDisabled = !permissions?.['mock-demo']?.update;
    const [mockPreview, setMockPreview] = useState('');
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator fields={showListData} record={record} {...props} />
    );
    const { id } = props;
    const breadcrumbsData = [
        {
            text: translation('resources.mock-demo.name'),
            url: 'mock-demo',
            icon: <ChildFriendlyIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const handleFileChange = (ev) => {
        const file = ev;
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const content = JSON.parse(e.target.result);
                    setMockPreview(JSON.stringify(content, null, 2));
                } catch (error) {
                    console.log(error);
                }
            };
            reader.readAsText(file);
        }
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    redirect="list"
                    toolbar={
                        <CustomToolbar
                            {...props}
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <ContainerFieldsList>
                        <TextInput
                            source="mockName"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    {!isEditDisabled && (
                        <FileInput
                            source="mocks"
                            accept="application/json"
                            onChange={handleFileChange}
                            validate={required()}
                            placeholder={
                                <p>
                                    {translation(
                                        'resources.mock.fields.input_file'
                                    )}
                                </p>
                            }
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                    )}
                    {mockPreview ? <pre>{mockPreview}</pre> : <AwsGetField />}
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default MockDemoEdit;
