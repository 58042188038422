import React from 'react';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import { FunctionField, TextField, TextInput, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const TroubleShootingFilter = (props) => {
    return (
        <CustomFilter {...props}>
            <TextInput
                label="resources.troubleshooting.fields.search"
                source="q"
                alwaysOn
            />
            <CustomSelectInput
                source="field"
                choices={[
                    { id: 'errorCode', name: 'Technical Error Code' },
                    { id: 'brand', name: 'Brand' },
                    { id: 'applianceType', name: 'Appliance Type' },
                    { id: 'applianceModel', name: 'Appliance Model' },
                    { id: 'title', name: 'Title' },
                    { id: 'category', name: 'Category' },
                    { id: 'platform', name: 'Platform' },
                ]}
                alwaysOn
            />
        </CustomFilter>
    );
};
const TroubleshootingList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.troubleshooting.name'),
            url: '',
            icon: <ErrorIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                pagination={<DynamoPagination resource="troubleshooting" />}
                exporter={false}
                filters={<TroubleShootingFilter />}
                {...props}
                bulkActionButtons={
                    permissions &&
                    permissions.troubleshooting &&
                    permissions.troubleshooting.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField
                        source="errorCode"
                        label="Technical Error Code"
                    />
                    <TextField source="brand" />
                    <TextField source="applianceType" />
                    <TextField source="applianceModel" />
                    <TextField source="loadingType" />
                    <TextField source="platform" />
                    <TextField source="title" />
                    <TextField source="category" />
                    <TextField source="connectivity" />
                    <FunctionField
                        label="Service App Sync"
                        render={(item) => {
                            return item.serviceAppSync && 'Enabled';
                        }}
                        source="serviceAppSync"
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default TroubleshootingList;
