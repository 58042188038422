import AssistantIcon from '@material-ui/icons/Assistant';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
} from 'candy-commons-components';
import React from 'react';
import { TextField, TextInput, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const AssistanceFilter = (props) => (
    <CustomFilter {...props}>
        <TextInput
            label="resources.assistance.fields.search"
            source="q"
            alwaysOn
        />
    </CustomFilter>
);
const AssistanceList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.assistance.name'),
            url: '',
            icon: <AssistantIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                exporter={false}
                perPage={25}
                {...props}
                pagination={<DynamoPagination resource="assistance" />}
                filters={<AssistanceFilter />}
                bulkActionButtons={
                    permissions &&
                    permissions.assistance &&
                    permissions.assistance.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="countryCode" />
                    <TextField source="brand" />
                    <TextField source="applianceType" />
                    <TextField source="isMultipleRecord" label="Multi Record" />
                    {/*<TextField source="country" />*/}
                    {/*<TextField source="phone" />*/}
                    {/*<TextField source="link" />*/}
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default AssistanceList;
