import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import {
    ArrayField,
    ChipField,
    SingleFieldList,
    TextField,
    useTranslate,
} from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const ApplianceInfoList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.appliance-info.name'),
            url: '',
            icon: <InfoIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                exporter={false}
                {...props}
                bulkActionButtons={
                    permissions &&
                    permissions['appliance-info'] &&
                    permissions['appliance-info'].delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="category" />
                    <ArrayField source="images">
                        <SingleFieldList>
                            <ChipField source="description" />
                        </SingleFieldList>
                    </ArrayField>
                    <TextField source="applianceType" />
                    <TextField source="description" />
                    <TextField source="title" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
const useStyles = makeStyles({});
export default ApplianceInfoList;
